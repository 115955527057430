<header class="app-header">
    <h1>My App</h1>
    <nav>
      <ul>
        <!-- <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Contact</a></li> -->
        <!-- <li><a href="#">Log-Out</a></li> -->
        <button mat-button (click)="logout()" matTooltip="Logout">
          <mat-icon>exit_to_app</mat-icon>
        </button>

      </ul>
    </nav>
  </header>
  