import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutDialogComponent } from '../../common/confirm.component';
import { Router } from '@angular/router';
import { LOGIN } from '../../constant/absolute-routes';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(private dialog: MatDialog, private router: Router) {}

  logout() {
    const dialogRef = this.dialog.open(ConfirmLogoutDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        localStorage.removeItem('survey_admin_token');  
        localStorage.removeItem('survey_admin_name'); 
        localStorage.removeItem('survey_admin_id'); 
        this.router.navigate([LOGIN]);
      }
    });
  }
}
